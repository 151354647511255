import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

export default function Contato() {
  return (
    <div className="bg-golden-50">
      <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
        <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-1 md:gap-8">
          <div>
            <h2 className="text-2xl font-bold text-cuida-900 sm:text-3xl sm:tracking-tight">
              Atendimento
            </h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
                Fale com um de nossos atendentes. Respondemos em algumas horas.
              </p>
            </div>
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <PhoneIcon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <a
                    className="block font-medium text-cuida-600 hover:text-cuida-500"
                    href="https://wa.me/5522992389523"
                  >
                    <p>(22)99238-9523</p>
                  </a>
                  <p className="mt-1">Este número é Whatsapp</p>
                  <p className="mt-1">Todos os dias de 9h às 19h.</p>
                </div>
              </div>
              {/* <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <EnvelopeIcon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <a
                    className="block font-medium text-cuida-600 hover:text-cuida-500"
                    href="mailto:contato@cuidajardim.com.br"
                  >
                    <p>contato@cuidajardim.com.br</p>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="mt-12 sm:mt-16 md:mt-0">
            <h2 className="text-2xl font-bold text-cuida-900 sm:text-3xl sm:tracking-tight">Technical Support</h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magni, repellat error corporis doloribus
                similique, voluptatibus numquam quam, quae officiis facilis.
              </p>
            </div>
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>+1 (555) 123 4567</p>
                  <p className="mt-1">Mon-Fri 8am to 6pm PST</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <EnvelopeIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>support@example.com</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
