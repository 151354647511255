import { CheckIcon } from "@heroicons/react/24/outline";

const features = [
  "Inspecionamos as condições do local",
  "Tratamos de forma adequada a terra com adubo e fertilizantes",
  "Plantamos flores, vegetais, legumes",
  "Resolvemos toda logística e transporte de materiais",
  "Cuidamos também de jardins verticais",
  "Podamos árvores, arbustos e matos selvagens",
  "Limpamos e recolhemos tudo no final do serviço",
  "Bônus: Você ganha um cheirinho de ambiente à sua escolha todo mês",
  "Bônus: Desconto em compras de produtos e contratação projetos",
];

const pricing = {
  tiers: [
    {
      title: "Todos",
      price: "R$800",
      frequency: "Sem compromisso",
      description:
        "Indicado para quem precisa de um cuidado pontual para o jardim ou decoração.",
      features: ["Sem fidelidade", "Serviços de plantio e manutenção"],
      cta: "Agendar visita (grátis)",
      mostPopular: false,
    },
    {
      title: "Residencial",
      price: "R$500",
      frequency: "Recorrente",
      description:
        "Manutenção periódica do seu jardim. Frequência a combinar.",
      features: [
        "Frequência a combinar",
        "Serviços de plantio e manutenção",
        "Pré-agendamento dos dias e horários",
      ],
      cta: "Agendar visita (grátis)",
      mostPopular: true,
    },
    {
      title: "Pousadas/Hotéis",
      price: "R$800",
      frequency: "Recorrente",
      description:
        "Serviços personalizados para pousadas e hotéis. Frequência a combinar.",
      features: [
        "Frequência a combinar",
        "Serviços de plantio e manutenção",
        "Pré-agendamento dos dias e horários",
        "Gestão de equipe profissional com escala",
        "Gestão e fornecimento de materiais e insumos",
        "Segurança e discrição para seu negócio"
      ],
      cta: "Agendar visita (grátis)",
      mostPopular: true,
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TabelaPrecos() {
  return (
    <div className="mx-auto max-w-7xl py-24 px-6 lg:px-8">
      <h2 className="text-3xl font-bold tracking-tight text-cuida-900 sm:text-5xl sm:leading-none lg:text-6xl">
        Preços para todos os jardins
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">
        Você quer transformar seu jardim em algo incrível, mas não sabe por onde
        começar? Não se preocupe! Temos os melhores profissionais para te ajudar
        a escolher o modelo perfeito para o seu jardim. E o melhor de tudo? A
        primeira visita é de graça! Então, o que está esperando? Agende agora e
        deixe que nós cuidemos do seu jardim!
      </p>


      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-cuida-900">
                {tier.title}
              </h3>

              {tier.mostPopular ? (
                <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-golden-500 py-1.5 px-4 text-sm font-semibold text-white">
                  Mais Popular
                </p>
              ) : null}
              <span className="block text-xl tracking-tight mt-4 text-gray-500">
                à partir de
              </span>
              <p className="flex items-baseline text-cuida-900">
                <span className="text-5xl font-bold tracking-tight">
                  {tier.price}
                </span>
              </p>
              <p className="flex items-baseline mt-2 text-cuida-900">
                <span className="ml-1 text-xl font-semibold">
                  {tier.frequency}
                </span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-cuida-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <a
              href="https://wa.me/5522992389523"
              className={classNames(
                tier.mostPopular
                  ? "bg-golden-500 text-white hover:bg-golden-600"
                  : "bg-golden-50 text-golden-700 hover:bg-golden-100",
                "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
              )}
            >
              {tier.cta}
            </a>
          </div>
        ))}
      </div>

      <div className="border-t mt-12 border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
        <div>
          <h2 className="text-lg font-semibold text-cuida-600">
            Tudo para o seu jardim
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-cuida-900">
            Em um único lugar
          </p>
          <p className="mt-4 text-lg text-gray-500">
            Seu jardim merece o melhor! Com nosso serviço completo, você tem
            tudo o que precisa em um único lugar. Desde adubação e plantio até
            manutenção e instalação. Deixe seu jardim impecável e desfrute dele
            ao máximo.
          </p>
        </div>
        <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
          <ul role="list" className="divide-y divide-gray-200">
            {features.slice(0, 5).map((feature, featureIdx) =>
              featureIdx === 0 ? (
                <li key={feature} className="flex py-4 md:py-0 md:pb-4">
                  <CheckIcon
                    className="h-6 w-6 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-base text-gray-500">
                    {feature}
                  </span>
                </li>
              ) : (
                <li key={feature} className="flex py-4">
                  <CheckIcon
                    className="h-6 w-6 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-base text-gray-500">
                    {feature}
                  </span>
                </li>
              )
            )}
          </ul>
          <ul
            role="list"
            className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0"
          >
            {features.slice(5).map((feature, featureIdx) =>
              featureIdx === 0 ? (
                <li
                  key={feature}
                  className="flex py-4 md:border-t-0 md:py-0 md:pb-4"
                >
                  <CheckIcon
                    className="h-6 w-6 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-base text-gray-500">
                    {feature}
                  </span>
                </li>
              ) : (
                <li key={feature} className="flex py-4">
                  <CheckIcon
                    className="h-6 w-6 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-base text-gray-500">
                    {feature}
                  </span>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
